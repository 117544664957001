import React, { createContext, useReducer } from 'react';
import type { FC, ReactNode } from 'react';
import { Collaborator } from 'src/types/collaborator';
import { collaboratorsDataProcessing } from 'src/utils/dataProcessing';

interface DataState {
  isProcessing: boolean;
  collaborators: Collaborator[] | null;
  collaboratorFetchedData: {
    id: string;
    name: string;
    email: string;
    phone?: string;
    nationality?: string;
    documentType?: string;
    documentNumber?: string;
  } | null;
}

interface DataContextValue extends DataState {
  processCollaboratorData: (data) => void;
  storeCollaboratorFetchedData: (
    data: {
      id: string;
      name: string;
      email: string;
      phone?: string;
      nationality?: string;
      documentType?: string;
      documentNumber?: string;
    } | null,
  ) => void;
  clearAll: () => void;
}

interface DataProviderProps {
  children: ReactNode;
}

type InitializeAction = {
  type: 'INITIALIZE';
  payload: {};
};

type FinalizedProcessingAction = {
  type: 'FINALIZED';
  payload: {
    collaborators: Collaborator[];
  };
};

type FetchCollaboratorByAccessCode = {
  type: 'STORE_COLLABORATOR_DATA';
  payload: {
    collaboratorFetchedData: {
      id: string;
      name: string;
      email: string;
      phone?: string;
      nationality?: string;
      documentType?: string;
      documentNumber?: string;
    } | null;
  };
};

type ClearData = {
  type: 'CLEAR_ALL';
  payload: {};
};

type Action = InitializeAction | FinalizedProcessingAction | FetchCollaboratorByAccessCode | ClearData;

const initialDataState: DataState = {
  isProcessing: false,
  collaborators: null,
  collaboratorFetchedData: null,
};

const reducer = (state: DataState, action: Action): DataState => {
  switch (action.type) {
    case 'INITIALIZE': {
      return {
        ...state,
        isProcessing: true,
      };
    }
    case 'FINALIZED': {
      const { collaborators } = action.payload;
      return {
        ...state,
        isProcessing: false,
        collaborators,
      };
    }
    case 'STORE_COLLABORATOR_DATA': {
      const { collaboratorFetchedData } = action.payload;
      return {
        ...state,
        isProcessing: false,
        collaboratorFetchedData,
      };
    }
    case 'CLEAR_ALL': {
      return {
        ...state,
        isProcessing: false,
        collaborators: null,
        collaboratorFetchedData: null,
      };
    }
    default: {
      return { ...state };
    }
  }
};

export const CollaboratorDataContext = createContext<DataContextValue>({
  ...initialDataState,
  processCollaboratorData: () => {},
  storeCollaboratorFetchedData: () => {},
  clearAll: () => {},
});

export const CollaboratorProvider: FC<DataProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialDataState);

  const processCollaboratorData = (data) => {
    const collaborators = collaboratorsDataProcessing(data);

    dispatch({
      type: 'FINALIZED',
      payload: {
        collaborators,
      },
    });
  };

  const storeCollaboratorFetchedData = (
    data: {
      id: string;
      name: string;
      email: string;
      phone?: string;
      nationality?: string;
      documentType?: string;
      documentNumber?: string;
    } | null,
  ) => {
    dispatch({
      type: 'STORE_COLLABORATOR_DATA',
      payload: {
        collaboratorFetchedData: data,
      },
    });
  };

  const clearAll = () => {
    dispatch({
      type: 'CLEAR_ALL',
      payload: {}
    })
  }

  return (
    <CollaboratorDataContext.Provider
      value={{
        ...state,
        processCollaboratorData,
        storeCollaboratorFetchedData,
        clearAll
      }}
    >
      {children}
    </CollaboratorDataContext.Provider>
  );
};