import React, { createContext, useReducer } from 'react';
import type { FC, ReactNode } from 'react';
import { Student } from 'src/types/student';
import { studentsDataProcessing } from 'src/utils/dataProcessing';

interface DataState {
  isProcessing: boolean;
  students: Student[] | null;
  studentFetchedData: {
    id: string;
    name: string;
    email: string;
    phone?: string;
    nationality?: string;
    documentType?: string;
    documentNumber?: string;
  } | null;
}

interface DataContextValue extends DataState {
  processStudentData: (data) => void;
  storeStudentFetchedData: (
    data: {
      id: string;
      name: string;
      email: string;
      phone?: string;
      nationality?: string;
      documentType?: string;
      documentNumber?: string;
    } | null,
  ) => void;
  clearAll: () => void;
}

interface DataProviderProps {
  children: ReactNode;
}

type InitializeAction = {
  type: 'INITIALIZE';
  payload: {};
};

type FinalizedProcessingAction = {
  type: 'FINALIZED';
  payload: {
    students: Student[];
  };
};

type FetchStudentByAccessCode = {
  type: 'STORE_STUDENT_DATA';
  payload: {
    studentFetchedData: {
      id: string;
      name: string;
      email: string;
      phone?: string;
      nationality?: string;
      documentType?: string;
      documentNumber?: string;
    } | null;
  };
};

type ClearData = {
  type: 'CLEAR_ALL';
  payload: {};
};

type Action = InitializeAction | FinalizedProcessingAction | FetchStudentByAccessCode | ClearData;

const initialDataState: DataState = {
  isProcessing: false,
  students: null,
  studentFetchedData: null,
};

const reducer = (state: DataState, action: Action): DataState => {
  switch (action.type) {
    case 'INITIALIZE': {
      return {
        ...state,
        isProcessing: true,
      };
    }
    case 'FINALIZED': {
      const { students } = action.payload;
      return {
        ...state,
        isProcessing: false,
        students,
      };
    }
    case 'STORE_STUDENT_DATA': {
      const { studentFetchedData } = action.payload;
      return {
        ...state,
        isProcessing: false,
        studentFetchedData,
      };
    }
    case 'CLEAR_ALL': {
      return {
        ...state,
        isProcessing: false,
        students: null,
        studentFetchedData: null,
      };
    }
    default: {
      return { ...state };
    }
  }
};

const DataContext = createContext<DataContextValue>({
  ...initialDataState,
  processStudentData: () => {},
  storeStudentFetchedData: () => {},
  clearAll: () => {},
});

export const DataProvider: FC<DataProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialDataState);

  const processStudentData = (data) => {
    const students = studentsDataProcessing(data);

    dispatch({
      type: 'FINALIZED',
      payload: {
        students,
      },
    });
  };

  const storeStudentFetchedData = (
    data: {
      id: string;
      name: string;
      email: string;
      phone?: string;
      nationality?: string;
      documentType?: string;
      documentNumber?: string;
    } | null,
  ) => {
    dispatch({
      type: 'STORE_STUDENT_DATA',
      payload: {
        studentFetchedData: data,
      },
    });
  };

  const clearAll = () => {
    dispatch({
      type: 'CLEAR_ALL',
      payload: {}
    })
  } 

  return (
    <DataContext.Provider
      value={{
        ...state,
        processStudentData,
        storeStudentFetchedData,
        clearAll
      }}
    >
      {children}
    </DataContext.Provider>
  );
};

export default DataContext;
