import React from 'react';
import type { FC, ReactNode } from 'react';
import { Redirect } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import useAuth from '../hooks/useAuth';

interface ApprovedGuardProps {
  children?: ReactNode;
}

const ApprovedGuard: FC<ApprovedGuardProps> = ({ children }) => {
  const { isApproved } = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  if (!isApproved) {
    enqueueSnackbar('User requires admin approval!', {
      variant: 'error'
    });
    return <Redirect to="/app/home" />;
  }

  return (
    <>
      {children}
    </>
  );
};

ApprovedGuard.propTypes = {
  children: PropTypes.node
};

export default ApprovedGuard;
