import React, { useContext } from 'react';
import type { FC } from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { FormControl, InputLabel, makeStyles } from '@material-ui/core';
import { Language } from '@material-ui/icons';
import { TranslationsContext } from 'src/contexts/TranslationContext';

interface SelectLangProps {
  [key: string]: any;
}

const useStyles = makeStyles((theme) => ({
  container: {
    zIndex: 3,
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  label: {
    position: 'relative',
    paddingTop: '16px',
  },
  select: {
    marginTop: '0 !important',
  },
  countryFlag: {
    marginRight: 10,
  },
}));


const SelectLang: FC<SelectLangProps> = (props) => {
  const classes = useStyles();

  const { dispatch, langs, selectedLang } = useContext(TranslationsContext);

  return (
    <FormControl className={classes.container}>
      <InputLabel id="language-select-label" className={classes.label}>
        <Language />
      </InputLabel>
      <Select
        className={classes.select}
        labelId="language-select-label"
        id="language-select-id"
        value={selectedLang}
        onChange={(event) => dispatch('new-language', event.target.value)}
      >
        {langs &&
          langs.map((lang) => (
            <MenuItem key={lang} value={lang}>
              {lang.toUpperCase()}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};

export default SelectLang;
