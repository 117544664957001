// eslint-disable-next-line
import React, { Suspense, Fragment, lazy } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import LoadingScreen from 'src/components/LoadingScreen';
import AuthGuard from 'src/components/AuthGuard';
import ApprovedGuard from 'src/components/ApprovedGuard';
import GuestGuard from './components/GuestGuard';

type Routes = {
  exact?: boolean;
  path?: string | string[];
  guard?: any;
  layout?: any;
  component?: any;
  routes?: Routes;
}[];

export const renderRoutes = (routes: Routes = []): JSX.Element => (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <Guard>
                <Layout>{route.routes ? renderRoutes(route.routes) : <Component {...props} />}</Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);

const routes: Routes = [
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('src/views/errors/NotFoundView')),
  },
  {
    exact: true,
    path: '/login',
    guard: GuestGuard,
    component: lazy(() => import('src/views/auth/AuthView')),
  },

  {
    exact: true,
    path: '/register',
    guard: GuestGuard,
    component: lazy(() => import('src/views/auth/AuthView')),
  },
  {
    exact: true,
    path: '/forgot-password',
    guard: GuestGuard,
    component: lazy(() => import('src/views/auth/ForgotPasswordView')),
  },
  {
    exact: false,
    path: '/confirm-new-password',
    guard: GuestGuard,
    component: lazy(() => import('src/views/auth/ConfirmPasswordView')),
  },
  {
    path: '/app',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/app/support',
        component: lazy(() => import('src/views/support')),
      },
      {
        exact: true,
        path: '/app/terms-and-conditions',
        component: lazy(() => import('src/views/terms-and-conditions')),
      },
      {
        exact: true,
        path: '/app/privacy-policy',
        component: lazy(() => import('src/views/privacy-policy')),
      },
      {
        exact: true,
        path: '/app/home',
        component: lazy(() => import('src/views/home')),
      },
      {
        exact: true,
        path: '/app/account',
        component: lazy(() => import('src/views/account/AccountView')),
      },
      {
        guard: ApprovedGuard,
        routes: [
          {
            exact: true,
            path: '/app/collaborators/list',
            component: lazy(() => import('src/views/collaborators/CollaboratorsList')),
          },
          {
            exact: true,
            path: '/app/collaborator/:collaboratorId',
            // guard: CollaboratorsRequiredGuard,
            component: lazy(() => import('src/views/collaborators/Collaborator')),
          },
          {
            exact: true,
            path: '/app/management/collaborators/add',
            component: lazy(() => import('src/views/collaborators/AddCollaborator')),
          },
          {
            exact: true,
            path: '/app/vaccines',
            component: lazy(() => import('src/views/vaccines')),
          },
          {
            exact: true,
            path: '/app/sick-leaves',
            component: lazy(() => import('src/views/sick-leaves')),
          },
          {
            exact: true,
            path: '/app/health-data',
            component: lazy(() => import('src/views/health-data')),
          },
          {
            exact: true,
            path: '/app/emergency-information',
            component: lazy(() => import('src/views/emergency-information')),
          },
          {
            exact: true,
            path: '/app/enterprise-group/reports',
            component: lazy(() => import('src/views/enterprise-group/CreateReports')),
          },
          {
            exact: true,
            path: '/app/enterprise-group/create',
            component: lazy(() => import('src/views/enterprise-group/Create')),
          },
          {
            exact: true,
            path: '/app/enterprise-group/edit',
            component: lazy(() => import('src/views/enterprise-group/Edit')),
          },
          {
            exact: true,
            path: '/app/enterprise-group/conversion',
            component: lazy(() => import('src/views/enterprise-group/Conversion')),
          },
          {
            exact: true,
            path: '/app/analytics/vaccines',
            component: lazy(() => import('src/views/analytics/vaccines')),
          },
          {
            exact: true,
            path: '/app/analytics/sick-leaves',
            component: lazy(() => import('src/views/analytics/sick-leaves')),
          },
          {
            exact: true,
            path: '/app/analytics/health-data',
            component: lazy(() => import('src/views/analytics/health-data')),
          },
          {
            exact: true,
            path: '/app/analytics/emergency-information',
            component: lazy(() => import('src/views/analytics/emergency-information')),
          }
        ],
      },
      {
        component: () => <Redirect to="/404" />,
      },
    ],
  },
  {
    exact: true,
    path: '*',
    component: () => <Redirect to="/app/home" />,
  },
];

export default routes;
