import _ from 'lodash';
import { Collaborator } from '../types/collaborator';
import { Student } from '../types/student';
import hasUndefined from './hasUndefined';

const EMERGENCY_REQUIRED_FIELDS = [
  'preferredEmergencyHospital',
  'preferredEmergencyHospitalPhone',
  'emergencyContacts',
];

const HEALTH_DATA_REQUIRED_FIELDS = [
  'doctorType',
  'specialNeeds',
  'allergicTo',
  'feverAllowedMedication',
  'congenitalDisease',
  'ongoingTreatments',
  'specificMedication',
  'bloodType',
  'rhFactor',
];

const checkVrc = (data: string): any => {
  switch (data) {
    case "PENDING":
      return {
        value: "PENDING",
        printColor: 'warning',
        message: 'STUDENT_STATUS_MESSAGE_STATUS_PENDING',
      }
    case "REDO":
      return {
        value: "REDO",
        printColor: 'warning',
        message: 'STUDENT_STATUS_MESSAGE_STATUS_REDO',
      }
    case "INCONSISTENT":
      return {
        value: "INCONSISTENT",
        printColor: 'error',
        message: 'STUDENT_STATUS_MESSAGE_STATUS_INCONSISTENT',
      }
    case "DENIED":
      return {
        value: "DENIED",
        printColor: 'error',
        message: 'STUDENT_STATUS_MESSAGE_STATUS_DENIED',
      }
    case "ALERT":
      return {
        value: "ALERT",
        printColor: 'error',
        message: 'STUDENT_STATUS_MESSAGE_STATUS_ALERT',
      }
    case "VERIFIED":
      return {
        value: "VERIFIED",
        printColor: 'success',
        message: 'STUDENT_STATUS_MESSAGE_STATUS_VERIFIED',
      }
    case "NR_32_VERIFIED":
      return {
        value: "NR_32_VERIFIED",
        printColor: 'nr32',
        message: 'STUDENT_STATUS_MESSAGE_STATUS_NR_32_VERIFIED',
      }
    default:
      return {
        printColor: 'error',
        message: 'STUDENT_STATUS_MESSAGE_NO_DATA',
      }
  }
}

const studentsDataProcessing = ({ users }): Student[] => {

  let usersData: Student[] = users.map((user) => {

    if (user.name === 'Jhonnatha de Andrade Monteiro 2') {
      console.log("jhonnatha data", {
        name: `${user.name} ${user.surname || ''}`,
        email: user.email,
        birthday: user.birthday,
        userId: user._id,
        mobile: user.mobile,
        documentType: user.documentType,
        documentNumber: user.documentNumber,
        officialVaccines: null,
        optionalVaccines: null,
        lastUpdate: user.updatedAt,
        healthForm: user.healthForm,
        missingHealthData: hasUndefined(user.healthForm?.healthData, HEALTH_DATA_REQUIRED_FIELDS)
          ? {
            printColor: 'warning',
            message: 'COLLABORATOR_STATUS_HEALTH_FORM_PENDING',
          }
          : {
            printColor: 'success',
            message: 'STUDENT_STATUS_HEALTH_FORM_SUBMITED',
          },
        missingEmergencyData: hasUndefined(user.healthForm?.emergencyData, EMERGENCY_REQUIRED_FIELDS)
          ? {
            printColor: 'warning',
            message: 'STUDENT_STATUS_HEALTH_FORM_PENDING',
          }
          : {
            printColor: 'success',
            message: 'STUDENT_STATUS_HEALTH_FORM_SUBMITED',
          },
        VCRStatus: checkVrc(user?.lastVCRStatus),
        vaccinationCard: user.vaccinationCard || null,
      })
    }

    return {
      name: `${user.name} ${user.surname || ''}`,
      email: user.email,
      birthday: user.birthday,
      userId: user._id,
      mobile: user.mobile,
      documentType: user.documentType,
      documentNumber: user.documentNumber,
      officialVaccines: null,
      optionalVaccines: null,
      lastUpdate: user.updatedAt,
      healthForm: user.healthForm,
      missingHealthData: hasUndefined(user.healthForm?.healthData, HEALTH_DATA_REQUIRED_FIELDS)
        ? {
          printColor: 'warning',
          message: 'STUDENT_STATUS_HEALTH_FORM_PENDING',
        }
        : {
          printColor: 'success',
          message: 'STUDENT_STATUS_HEALTH_FORM_SUBMITED',
        },
      missingEmergencyData: hasUndefined(user.healthForm?.emergencyData, EMERGENCY_REQUIRED_FIELDS)
        ? {
          printColor: 'warning',
          message: 'STUDENT_STATUS_HEALTH_FORM_PENDING',
        }
        : {
          printColor: 'success',
          message: 'STUDENT_STATUS_HEALTH_FORM_SUBMITED',
        },
      VCRStatus: checkVrc(user?.lastVCRStatus),
      vaccinationCard: user.vaccinationCard || null,
    };
  });

  return usersData;
};

const collaboratorsDataProcessing = ({ users }): Collaborator[] => {

  let usersData: Collaborator[] = users.map((user) => {

    return {
      name: `${user.name} ${user.surname || ''}`,
      email: user.email,
      birthday: user.birthday,
      userId: user._id,
      mobile: user.mobile,
      documentType: user.documentType,
      documentNumber: user.documentNumber,
      officialVaccines: null,
      optionalVaccines: null,
      lastUpdate: user.updatedAt,
      healthForm: user.healthForm,
      missingHealthData: hasUndefined(user.healthForm?.healthData, HEALTH_DATA_REQUIRED_FIELDS)
        ? {
          printColor: 'warning',
          message: 'COLLABORATOR_STATUS_HEALTH_FORM_PENDING',
        }
        : {
          printColor: 'success',
          message: 'COLLABORATOR_STATUS_HEALTH_FORM_SUBMITTED',
        },
      missingEmergencyData: hasUndefined(user.healthForm?.emergencyData, EMERGENCY_REQUIRED_FIELDS)
        ? {
          printColor: 'warning',
          message: 'COLLABORATOR_STATUS_HEALTH_FORM_PENDING',
        }
        : {
          printColor: 'success',
          message: 'COLLABORATOR_STATUS_HEALTH_FORM_SUBMITTED',
        },
      VCRStatus: checkVrc(user?.lastVCRStatus),
      vaccinationCard: user.vaccinationCard || null,
    };
  });

  return usersData;
};

const vaccinePlotData = (students: Student[]) => {
  const vaccineColorMap = {
    warning: '#F9E708',
    error: '#DB0623',
    success: '#00AFAA',
    primary: '#ACD7FE',
  };

  const totalStudents = students.length;
  const studentsGroupedByStatusMessage = _.groupBy(students, 'status.message');
  const studentsStatus = Object.keys(studentsGroupedByStatusMessage);

  let printColor = [];
  let percentages = [];
  studentsStatus.forEach((students) => {
    printColor.push(vaccineColorMap[studentsGroupedByStatusMessage[students][0].status.printColor]);
    percentages.push(Math.round((studentsGroupedByStatusMessage[students].length / totalStudents) * 100));
  });

  const result = {
    studentsStatus,
    printColor,
    percentages,
  };

  return result;
};

const overviewData = (students: Student[]) => {
  const totalStudents = students.length;
  const studentsGroupedByStatusMessage = _.groupBy(students, 'status.message');
  return { totalStudents, studentsGroups: studentsGroupedByStatusMessage };
};

const allergyData = (students: Student[]) => {
  const resultPartial = students.filter((student) => student.healthForm && student.healthForm.healthData?.allergicTo);
  const result = resultPartial.map((student) => ({
    name: student.name,
    email: student.email,
    allergicTo: student.healthForm.healthData.allergicTo || 'Error: Info not Available',
  }));

  return result;
};

export {
  collaboratorsDataProcessing,
  studentsDataProcessing,
  checkVrc,
  vaccinePlotData,
  overviewData,
  allergyData
};
