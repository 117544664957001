import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next'; 
import { AVAILABLE_LANGUAGES, DEFAULT_LANGUAGE } from 'src/constants'


interface LanguageContextValue {
  dispatch: (action: string, data: any) => void;
  langs: string[];
  selectedLang: string;
}

export const TranslationsContext = React.createContext<LanguageContextValue>({
  dispatch: () => {},
  langs: [],
  selectedLang: ''
});

export const TranslationsProvider = ({ children }) => {
  const storedLanguage = localStorage.getItem('language');
  const langs = AVAILABLE_LANGUAGES;

  const [selectedLang, setSelectedLang] = useState(storedLanguage || DEFAULT_LANGUAGE);

  const { i18n } = useTranslation();
  const changeLanguage = code => {
    i18n.changeLanguage(code);
  };
  
  
  useEffect(() => {
    changeLanguage(selectedLang);
    // eslint-disable-next-line
  }, [selectedLang]);

  const reducer = (action, data) => {
    switch (action) {
      case 'new-language':
        localStorage.setItem('language', data);
        setSelectedLang(data);
        break;
      default:
      //
    }
  };

  return (
    <TranslationsContext.Provider
      value={{
        langs,
        selectedLang,
        dispatch: reducer,
      }}
    >
      {children}
    </TranslationsContext.Provider>
  );
};
