import React from 'react';
import type { FC } from 'react';

interface LogoProps {
  [key: string]: any;
}

const Logo: FC<LogoProps> = (props) => {
  return (
    <div
      style={{
        backgroundImage: "url('/static/black_logo.svg')",
        backgroundSize: "cover",
        fill:"green",
        width: 60,
        height: 60,
        ...props
      }}
    >

    </div>
  );
}

export default Logo;
