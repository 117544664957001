export const APP_VERSION = '2.1.0';

export const ENABLE_REDUX_DEV_TOOLS = true;

export const THEMES = {
  LIGHT: 'LIGHT',
  ONE_DARK: 'ONE_DARK',
  UNICORN: 'UNICORN',
};

export const AVAILABLE_LANGUAGES = ['en', 'es', 'fr', 'pt'];
export const FALLBACK_LANGUAGE = 'pt';
export const DEFAULT_LANGUAGE = 'pt';
